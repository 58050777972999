<script>import { Formrow, api, Button, Alert, toasterStore, IconSpinner } from "duo-kit";
import AddressSearch from "./address-search.svelte";
import { onMount } from "svelte";
let user = {};
let countries = [];
let states = [];
let message = "";
let customAddress = false;
let saving = false;
onMount(() => {
  let params = new URLSearchParams(window.location.search);
  user.referral = params.get('ref');
  load();
});

async function load() {
  countries = (await api.load({
    url: "/duocms/api/countries"
  })).data;
  states = (await api.load({
    url: `/duocms/api/states`
  })).data;
}

function countryFromCode(code) {
  if (!code) return "";
  let country = countries.find(c => c.code == code);
  console.log({
    country,
    code,
    countries
  });
  return country ? country.name : "";
}

function stateFromCode(code) {
  if (!code) return "";
  let state = states.find(c => c.code == code);
  return state ? state.name : "";
}

async function register(e) {
  e.preventDefault();

  if (!user.address || !user.city || !user.postcode || !user.country) {
    toasterStore.add({
      type: "danger",
      title: "Sorry!",
      message: "Please supply a valid address"
    });
    return;
  }

  saving = true;
  let res;

  try {
    res = await api.save({
      url: "/duocms/api/register",
      data: user,
      method: "POST"
    });
    message = res.data.message;
    user = {
      first_name: "",
      surname: "",
      email: "",
      company: "",
      address: "",
      city: "",
      postcode: "",
      country: "",
      is_student: false
    };
    let params = new URLSearchParams(window.location.search);
    user.referral = params.get('ref');
  } catch (err) {} // errors shown in toaster...


  saving = false;
}</script>

<form on:submit={register}>
  <Formrow label="First Name" required type="text" id="first_name" bind:value={user.first_name} />
  <Formrow label="Surname" required type="text" id="surname" bind:value={user.surname} />
  <Formrow label="Email" required type="email" id="email" bind:value={user.email} />
  <Formrow label="I am a current student, with an official university email address" type="checkbox" id="is_student" bind:checked={user.is_student} />
  {#if user.is_student}
    <div class="inset">
      <Alert mode="warning">Please ensure you enter your valid university email address above</Alert>
    </div>
  {:else if !user.referral && !user.is_student}
    <Formrow label="My organisation is a NAFEMS Member" type="checkbox" id="member_check" bind:checked={user.member_check} />
    <Formrow label="Organisation" type="text" id="company" bind:value={user.company} />
  {/if}
  <Formrow label="Organisation Address Search">
    <AddressSearch bind:custom={customAddress} bind:user={user} countries={countries} />
  </Formrow>
  {#if customAddress}
  <Formrow label="Address" required type="textarea" rows={3} id="address" bind:value={user.address} />
  <Formrow label="City" required type="text" id="company" bind:value={user.city} />
  <Formrow label="Postcode" required type="text" id="postcode" bind:value={user.postcode} />
  <Formrow label="Country" required type="select" id="country" bind:value={user.country}>
    <option value="">Select a country</option>
    {#each countries as country}
      <option value={country.code}>{country.name}</option>
    {/each}
  </Formrow>
  {:else}
    <div class="addresswrap">
      {#if user.address}
        <Formrow label="Address" id="address">
          <div class="address">{user.address||""}</div>
        </Formrow>
      {/if}
      {#if user.city}
        <Formrow type="static" label="City" id="city" bind:value={user.city} />
      {/if}
      {#if user.country=="US" && user.state}
        <Formrow type="static" label="State" required id="state" value={user.state ? stateFromCode(user.state) : ""} />
      {/if}
      {#if user.postcode}
        <Formrow type="static" label="Postcode" id="postcode" value={user.postcode || ""} />
      {/if}
      {#if user.country && countries.length}
        <Formrow type="static" label="Country" required id="country" value={user.country ? countryFromCode(user.country) : ""} />
      {/if}
    </div>
  {/if}

  <div class="inset">
    <Button mode="dark" type="submit" disabled={saving}>
      {#if saving}<IconSpinner /> {/if}
      Sign Up
    </Button>
  </div>
</form>
  
  {#if message}
    <Alert mode="success">{message}</Alert>
  {/if}

<style>
  form {
    --s-border-radius:0px;
    --s-labelwidth:300px;
    --s-button-margins:0;
    margin-block:20px;
  }
  .inset{
    margin-left:calc( var(--s-labelwidth) + 12px );
    margin-right:5px;
  }

  @media (max-width: 599px) {
    form{
      --s-labelwidth:0px;
    }
    .inset{
      margin-left:5px;
    }
    .addresswrap :global(.form-row .form-input-static),.addresswrap .address{
      padding-inline:3px;
    }
  }

  .addresswrap{
    --s-formrow-margins:0;
  }

  .address{
    white-space:pre-wrap;
    padding:8px;
  }


</style>
